/* You can add global styles to this file, and also import other style files */

.pbd0{
    padding-bottom: 0px !important;
}

.formHeading{
    font-size:1.6em !important;
}

.texttooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}
  
.texttooltip .tooltiptext {
    
    visibility: hidden;
    max-width : 700px;
    width: max-content;
    line-height: 17px;
    letter-spacing: .3px;
    top: -3px;
    left: 20px;
    background-color: #458e9a;
    color: #fbfbfb;
    text-align: left;
    padding: 5px 7px;
    border-radius: 6px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}
  
.texttooltip:hover .tooltiptext {
    visibility: visible;
}

.texttooltip .tooltiptext::after{
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #458e9a transparent transparent;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

.stats-bg{
    background:#d8d6d6;
}

.toggle {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 22px;
    margin-left: 5px;
  }
  
  .toggle input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .knob {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .knob:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .knob {
    background-color: #2196F3;
  }
  
  input:focus + .knob {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .knob:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
  
  /* Rounded sliders */
  .knob.round {
    border-radius: 34px;
  }
  
  .knob.round:before {
    border-radius: 50%;
  }

.ads-bg{
    background:aliceblue;
}

.ad-settings{
    font-size: medium; width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

del{
    background-color: pink;
}

ins{
    background-color: lightgreen;
}