//Variables
$sideber-border: #E4E7ED;

.chat-application{
	/* .app-content, .content-right, .content-wrapper, .content-body{
		height: 100%;
	} */
	overflow:hidden;
	.content-wrapper{
		padding: 0 !important;
	}
	.chat-sidebar{
		background: white;
		position:relative;
		width: 300px;
		vertical-align: top;
		height: calc(100% - 20px);
		border-right: 1px solid $sideber-border;
    	z-index: 999;
	}
	.chat-fixed-search{
		width: 300px;
		position: fixed;
    	z-index: 999;
    	background: $white;
    	border-bottom: 1px solid $sideber-border;
    	border-right: 1px solid $sideber-border;
	}
	.users-list-padding{
		padding-top:90px;
	}
	.list-group-item-heading{
		font-size: 0.8rem;
		font-weight: 500;
		span{
			font-size: 0.8rem;
			font-weight: 400;
		}
	}
	.chat-app-window{
		padding: 20px 30px;
	    overflow-y: scroll;
	    text-align: center;    
	    height: calc(100% - 112px);
	    background-color: #fff;
	}
	.chat-app-form{
		position: relative;
	    padding: 20px 10px;
	    overflow: hidden;
	}
	.chats {
	    padding: 0;
	    .chat-body {
		    display: block;
		    margin: 10px 30px 0 0;
		    overflow: hidden;
		    .chat-content {
		    	&:before{
		    		position: absolute;
				    top: 10px;
				    right: -10px;
				    width: 0;
				    height: 0;
				    content: '';
				    border: 5px solid transparent;
				    border-left-color: theme-color('primary');
		    	}
		    	+.chat-content:before {
				    border-color: transparent;
				}
		    	text-align: right;
			    position: relative;
			    display: block;
			    float: right;
			    padding: 8px 15px;
			    margin: 0 20px 10px 0;
			    clear: both;
			    color: #fff;
			    background-color: theme-color('primary');
			    border-radius: 4px;
			    p{
			    	margin: 0;
			    }
			}
		}		
		.chat-avatar{			
    			float: right;
			.avatar {
			    width: 50px;
			    margin-top: -10px
			}
		}
		.time {
		    color: $gray-500;
		    font-size: 12px;
		    text-align: center;
		    margin: 40px 0
		}
		.chat-left {
			.chat-avatar{
				float: left;
			}
			.chat-body {
				margin-right: 0;
    			margin-left: 30px;
			}
			.chat-content{
				+.chat-content:before {
				    border-color: transparent;
				}
				&:before{					
					right: auto;
				    left: -10px;
				    border-right-color: lighten($body-bg, 18%);
				    border-left-color: transparent;
				}
				text-align: left;
				float: left;
				margin: 0 0 10px 20px;
    			color: theme-color("dark");
    			background-color: $body-bg;
			}
		}		
	}
	.form-control-position{
		cursor: pointer;
		&.control-position-right{
			right: 18px;
			top: 2px;
			cursor: pointer;
		}
	}
	@include media-breakpoint-down(sm) {
		.chat-app-window{		
		    height: calc(100% - 132px);
		}
	}
}