@import "plugins/chartist";
@import "plugins/perfect-scrollbar/main";
@import "plugins/plugin-nouislider";
@import "plugins/datatables";
.surfer-guidelines {
    height: 100%;
    right: 0;
    bottom: 0;
    border: 1px solid #ECEEF3;
    border-left: none;
    width: 100%;
}

.surfer-guidelines.surfer-guidelines-wide {
    width: 100vw;
}