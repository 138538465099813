.navbar {
	z-index: 4; // required to make the shadow overlap the below content (need is visible in mobile drawer overlay)
	border: 0;
	border-radius: 0;

	.navbar-brand {
		position: relative;
	}

	.navbar-nav {
		display: block;
		margin-right: -18px;

		.dropdown-menu {
			position: absolute;
		}
	}

	.navbar-toggle {
		background-color: transparent;
		border: none;

		.icon-bar {
			background-color: inherit;
			border: 1px solid;

			&+.icon-bar {
				margin-top: 4px;
			}
		}
	}

	.notification {
		position: absolute;
		top: -1px;
		right: 13px;
	}
}
.arrow-top{
    position: absolute;
    display: block;
    margin-left:-4px;
}
.arrow-top::before, .arrow-top::after{
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    right: 28px;
}
.arrow-top::before{
    top: -7px;
    border-width: 0 .5rem .5rem;
    border-bottom-color: rgba(0,0,0,.25);
}
.arrow-top::after{
    top: -6px;
    border-width: 0 .5rem .5rem;
    border-bottom-color: #fff;
}
.tableStats{
    span{
        vertical-align: bottom;
        padding: 6px 12px;
        margin-top: 0px;
        display: inline-block;
    }
}
.limitlist{
    display: flex;
    padding: 0px 12px;
    div{
      margin-right: 10px;
    }
}