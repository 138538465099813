@import "scss/variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "scss/core/palette";
@import "scss/app-variables";
@import "scss/mixins";
@import "scss/custom-bootstrap";
@import "scss/core";
@import "scss/fonts/weather-icons";
@import "scss/plugins";
@import "../fonts/simple-line-icons/style.min.css";
@import "../../../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";