hr{
  border-color: #a8a8a8;
}
ul.list-inline{
  i{
    line-height: 18px;
  }
}


.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 2s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: theme-color("primary");
  }
  50% {
    stroke: theme-color("warning");
  }
  100% {
    stroke: theme-color("primary");
  }
}


/*           Animations              */
.animation-transition-general{
    @include transition($general-transition-time, $transition-linear);
}

.animation-transition-slow{
    @include transition($slow-transition-time, $transition-linear);
}

.animation-transition-fast{
    @include transition($fast-transition-time, $transition-ease);
}
legend {
  border-bottom: 0;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: 0;
  }
}
a:focus, a:active,
button:active, button:focus, button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    outline : 0 !important;
}
.table-borderless{
  tbody{
    tr{
      td,th{
        border: 0;
      }
    }
  }
}


datetime .form-group{
  margin: 0
}
.btn-round{
  border-radius: $border-radius-extreme;
}
.fonticon-container{
	> .fonticon-wrap{
		float: left;
		width: 60px;
		height: 60px;
		line-height: 4.8rem;
		text-align: center;
		border-radius: 0.1875rem;
		margin-right: 1rem;
		margin-bottom: 1.5rem;
		> i{
			font-size: 2.28rem;
      &.fa{
			  font-size: 2.28rem !important;        
      }
			transition: all .2s ease-in-out;
		}
		&.youtube{
			width: 100px;
		}
	}
	&:hover{		
		i{
			color: theme-color('primary');
			font-size: 2.9rem;
			transform: scale(1.3);
		}
	}

	>.fonticon-classname, >.fonticon-unit{
		display: block;
    	font-size: 1.5rem;
	    line-height: 1.2;
	}

	>.fonticon-unit{
		font-size: 1rem;
		font-style: italic;
	}
}

.notification-dropdown{
  .noti-list{
    position: relative;
    height: 290px;
  }
  &.dropdown-menu{
    padding: 0;
  }

  width: 400px;

  .noti-text{
    color: $gray-600;
    font-size: 90%;
  }

  .dropdown-item:focus, .dropdown-item:hover{
    i{
      color: theme-color('primary') !important;
    }
  }

  .dropdown-item.active, .dropdown-item:active{
    i,.noti-title, .noti-text{
      color: white !important;
    }
  }
}

// Checkbox css
.custom-control-input:active ~ .custom-control-indicator {
  background-color: theme-color('primary');
}

// Form Control
.form-control{
  &:focus{
    border-color: theme-color('primary') !important;
  }
}
.top-back-btn {
    float: left;
    margin: -7px 10px 0 0px;
}
.content-loader {
    color: #000;
    width: 100%;
    text-align: center;
    padding: 10% 0;
    font-size: 40px;
}
ng-pagination select {
    margin-right: 15px;
}

.collapse.in{
    display: block;
}
.side-description img {
    max-width: 100% !important;
}

.menu-collapsed ul.menu-content {
    display: none;
}

.navigation select.form-control { display: none; }

.editor-highlight{
    background:yellow;font-weight:bold;
    cursor: pointer;
}

.editor-topic{
    color:#ff6000;font-weight:bold;
    cursor: pointer;
}

.sm-modal .modal-dialog{
    max-width: 65vw !important;
}

.custom-modal .modal-dialog{
    width: 90vw!important;
    max-width: 100%!important;
}

.compare_description {
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    overflow-wrap: break-word;
    border: .5px solid gray;
    p img{
        max-width: 100%;
    }
}

.compare_titile {
    overflow-wrap: break-word;
    border: .5px solid gray;
    padding: 10px;
}


.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

.comment-txt{
    -webkit-text-size-adjust: 100%;
    --swiper-theme-color: #007aff;
    --swiper-navigation-size: 44px;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #975aff;
    --secondary: #616d89;
    --success: #40c057;
    --info: #2f8be6;
    --warning: #f77e17;
    --danger: #f55252;
    --light: #dbdee5;
    --dark: #1a051d;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Montserrat","Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-family: Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    display: block;
    padding: 8px 15px;
    clear: both;
    border-radius: 4px;
    text-align: left;
    float: left;
    margin: 0 0 10px 20px;
    color: #342e49;
    background-color: #f7f7f8;
}

.comment-txt>p{
    -webkit-text-size-adjust: 100%;
    --swiper-theme-color: #007aff;
    --swiper-navigation-size: 44px;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #975aff;
    --secondary: #616d89;
    --success: #40c057;
    --info: #2f8be6;
    --warning: #f77e17;
    --danger: #f55252;
    --light: #dbdee5;
    --dark: #1a051d;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Montserrat","Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-family: Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #342e49;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    letter-spacing: .3px;
    margin: 0;
}

.reply-text{
    -webkit-text-size-adjust: 100%;
    --swiper-theme-color: #007aff;
    --swiper-navigation-size: 44px;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #975aff;
    --secondary: #616d89;
    --success: #40c057;
    --info: #2f8be6;
    --warning: #f77e17;
    --danger: #f55252;
    --light: #dbdee5;
    --dark: #1a051d;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Montserrat","Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-family: Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
    position: relative;
    display: block;
    float: left;
    padding: 8px 15px;
    margin: 0 20px 10px 50px;
    clear: both;
    color: #fff;
    background-color: #975aff;
    border-radius: 4px;
}

.reply-text>p{
    -webkit-text-size-adjust: 100%;
    --swiper-theme-color: #007aff;
    --swiper-navigation-size: 44px;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #975aff;
    --secondary: #616d89;
    --success: #40c057;
    --info: #2f8be6;
    --warning: #f77e17;
    --danger: #f55252;
    --light: #dbdee5;
    --dark: #1a051d;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Montserrat","Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    font-family: Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #fff;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    letter-spacing: .3px;
    margin: 0;
}